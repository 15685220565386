/* @import 'https://fonts.googleapis.com/css?family=Montserrat:400,500,700'; */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: 0 0
}

article,
aside,
footer,
header,
hgroup,
nav,
section,
figure,
figcaption {
	display: block;
	margin: 0
}

* {
	-webkit-tap-highlight-color: transparent
}

input,
textarea {
	-webkit-border-radius: 0;
	border-radius: 0
}

input {
	-webkit-appearance: none
}

body {
	-webkit-text-size-adjust: none
}

ol,
ul {
	list-style: none
}

blockquote,
q {
	quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none
}

:focus {
	outline: 0
}

::-moz-focus-inner {
	border: 0
}

ins {
	text-decoration: none
}

del {
	text-decoration: line-through
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

*,
*:after,
*:before {
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

::-moz-selection {
	background: #0f7d64;
	color: #fff
}

::selection {
	background: #0f7d64;
	color: #fff
}

input[type=checkbox] {
	-webkit-appearance: checkbox
}

input[type=radio] {
	-webkit-appearance: radio
}

a {
	color: #b82464;
	font-family: wppregular, Arial, sans-serif;
	padding: 0;
	font-style: normal;
	text-decoration: none;
	-webkit-transition: all .3s ease 0s;
	-moz-transition: all .3s ease 0s;
	-o-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

a:hover {
	color: #333
}

/*!Generated by Font Squirrel (https://www.fontsquirrel.com) on February 27, 2019*/
@font-face {
	font-family: wppblack;
	src: url(../fonts/wpp-black.woff2) format('woff2'), url(../fonts/wpp-black.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppblack_italic;
	src: url(../fonts/wpp-blackitalic.woff2) format('woff2'), url(../fonts/wpp-blackitalic.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppbold;
	src: url(../fonts/wpp-bold.woff2) format('woff2'), url(../fonts/wpp-bold.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppbold_italic;
	src: url(../fonts/wpp-bolditalic.woff2) format('woff2'), url(../fonts/wpp-bolditalic.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wpplight;
	src: url(../fonts/wpp-light.woff2) format('woff2'), url(../fonts/wpp-light.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wpplight_italic;
	src: url(../fonts/wpp-lightitalic.woff2) format('woff2'), url(../fonts/wpp-lightitalic.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppmedium;
	src: url(../fonts/wpp-medium.woff2) format('woff2'), url(../fonts/wpp-medium.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppmedium_italic;
	src: url(../fonts/wpp-mediumitalic.woff2) format('woff2'), url(../fonts/wpp-mediumitalic.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppregular;
	src: url(../fonts/wpp-regular.woff2) format('woff2'), url(../fonts/wpp-regular.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppitalic;
	src: url(../fonts/wpp-regularitalic.woff2) format('woff2'), url(../fonts/wpp-regularitalic.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppthin;
	src: url(../fonts/wpp-thin.woff2) format('woff2'), url(../fonts/wpp-thin.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: wppthin_italic;
	src: url(../fonts/wpp-thinitalic.woff2) format('woff2'), url(../fonts/wpp-thinitalic.woff) format('woff');
	font-weight: 400;
	font-style: normal
}

@font-face {
	font-family: icomoon;
	src: url(../fonts/icomoon.eot?z26t3p);
	src: url(../fonts/icomoon.eot?z26t3p#iefix) format('embedded-opentype'), url(../fonts/icomoon.ttf?z26t3p) format('truetype'), url(../fonts/icomoon.woff?z26t3p) format('woff'), url(../fonts/icomoon.svg?z26t3p#icomoon) format('svg');
	font-weight: 400;
	font-style: normal
}

[class^=icon-],
[class*=" icon-"] {
	font-family: icomoon !important;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.icon-short-arrow-down:before {
	content: "\e90e"
}

.icon-short-arrow-up:before {
	content: "\e90f"
}

.icon-arrow-down:before {
	content: "\e90d"
}

.icon-main-menu:before {
	content: "\e90c"
}

.icon-minus:before {
	content: "\e90a"
}

.icon-plus:before {
	content: "\e90b"
}

.icon-short-arrow-right:before {
	content: "\e908"
}

.icon-short-arrow-left:before {
	content: "\e909"
}

.icon-play-button:before {
	content: "\e907"
}

.icon-arrow-left:before {
	content: "\e902"
}

.icon-arrow-right:before {
	content: "\e903"
}

.icon-fb:before {
	content: "\e904"
}

.icon-twitter:before {
	content: "\e905"
}

.icon-you-tube:before {
	content: "\e906"
}

.icon-close:before {
	content: "\e901"
}

.icon-icon-search:before {
	content: "\e900"
}

body {
	color: #545553;
	font-family: montserrat, sans-serif;
	font-size: 18px;
	position: relative;
	background-color: #fff;
	overflow-x: hidden
}

html,
body {
	height: 100%
}

img {
	max-width: 100%;
	width: 100%;
	height: auto;
	padding: 0;
	margin: 0;
	border: none;
	display: block
}

p {
	color: #4a4a4a;
	padding: 0;
	text-align: left;
	text-decoration: none;
	font-weight: 400;
	display: block;
	line-height: normal;
	margin: 15px 0;
	line-height: 25px
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	line-height: normal;
	letter-spacing: normal;
	text-decoration: none
}

.wrapper {
	display: inherit;
	max-width: 1440px;
	height: auto;
	margin: 0 auto;
	padding: 0 90px;
	width: 100%;
	position: relative
}

header {
	position: fixed;
	z-index: 1000;
	width: 100%
}

header .top-bar {
	background-color: rgba(0, 0, 0, .62);
	padding: 0 68px;
	display: table;
	width: 100%;
	line-height: 66px;
	position: relative
}

header .top-bar .col.left {
	display: table-cell;
	vertical-align: middle;
	padding: 0;
	width: 251px
}

header .top-bar .col.right,
header .bottom-bar .col.right {
	text-align: right;
	display: table-cell;
	vertical-align: middle;
	width: 5%
}

header .top-bar .col-middle {
	text-align: left;
	display: table-cell;
	vertical-align: middle;
	padding-left: 30px
}

header .btn-search {
	display: inline-block;
	position: relative;
	height: 60px;
	width: 60px;
	text-align: center;
	line-height: 60px;
	font-size: 20px;
	font-weight: 700;
	color: #fff;
	vertical-align: middle;
	z-index: 2000;
	text-decoration: none;
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

header .btn-search:before {
	position: relative;
	z-index: 2
}

header .btn-search:after {
	content: '';
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translateX(-50%) translateY(-50%) scale(0);
	-moz-transform: translateX(-50%) translateY(-50%) scale(0);
	-ms-transform: translateX(-50%) translateY(-50%) scale(0);
	-o-transform: translateX(-50%) translateY(-50%) scale(0);
	transform: translateX(-50%) translateY(-50%) scale(0);
	height: 100%;
	width: 100%;
	background-color: #50b848;
	border-radius: 50%;
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

header .top-bar .logo {
	display: block;
	max-width: 251px;
	vertical-align: middle;
	padding: 0;
	margin: 0;
	z-index: 20
}

.logo {
	display: block;
	max-width: 250px;
	vertical-align: middle;
	padding: 0;
	margin: 0;
	z-index: 20
}

#main_nav {
	font-size: 0
}

ul#main_nav {
	text-align: right
}

#main_nav>li {
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px
}

#main_nav>li:first-child {
	margin-left: 0
}

#main_nav>li.has-children>a {
	padding-right: 0
}

#main_nav>li>a {
	display: block;
	position: relative;
	text-transform: uppercase;
	color: #fff;
	padding: 0;
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 100px;
	letter-spacing: normal;
	cursor: pointer;
	font-family: wppbold, Arial, sans-serif
}

#main_nav>li>a:hover {
	color: #c60e3b
}

#main_nav>li>a:active {
	background-color: transparent;
	opacity: 1;
	border-top: 5px solid #000050
}

.has-children {
	padding: 0 2%
}

#main_nav .sub-menu {
	position: absolute;
	width: 100%;
	padding: 40px 68px 40px 505px;
	left: 0;
	top: 100px;
	background-color: #fff;
	display: none;
	z-index: 600;
	box-shadow: inset 0 12px 8px -11px #ccc
}

#main_nav .sub-menu ul {
	width: 100%;
	padding: 0
}

#main_nav .sub-menu li a {
	font-family: wpp medium, Arial, sans-serif;
	display: block;
	font-size: 12px;
	color: #084e8a;
	padding: 10px 0;
	line-height: 9px;
	position: relative;
	cursor: pointer;
	font-weight: 700
}

#main_nav li.active.has-children>a {
	border-top: 4px solid #ed174c
}

.sub-menu h1 {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 12px;
	display: block;
	text-transform: uppercase;
	font-weight: bolder;
	line-height: 2px;
	letter-spacing: normal;
	color: #084e8a
}

#main_nav>li.has-children:hover .sub-menu {
	display: none
}

#main_nav .sub-menu li a:hover {
	color: #ed174c
}

#main_nav .sub-menu li a span {}

#main_nav .sub-menu ul.three-col {
	-webkit-columns: 3;
	-moz-columns: 3;
	columns: 3;
	display: block
}

#main_nav .sub-menu ul.twoo-col {
	display: table
}

.twoo-col .col-left {
	display: table-cell;
	vertical-align: text-top;
	width: 20%;
	text-align: left;
	padding: 0
}

.twoo-col .col-right {
	display: table-cell;
	vertical-align: text-top;
	text-align: right
}

.main-search {
	position: absolute;
	z-index: 1500;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #1b1b1b;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: opacity .3s, visibility .3s;
	-moz-transition: opacity .3s, visibility .3s;
	transition: opacity .3s, visibility .3s
}

.main-search .tbl {
	display: table;
	width: 100%;
	height: 100%;
	position: relative;
	padding: 0 68px
}

.main-search form {
	position: relative;
	height: 100%;
	display: table-cell;
	vertical-align: middle
}

.main-search input[type=text-search] {
	width: 100%;
	color: #fff;
	font-size: 2.4rem;
	height: 100%;
	background-color: transparent;
	padding-right: 140px;
	border: 0;
	display: block;
	font-family: wppregular, Arial, sans-serif;
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

.main-search .btn-search-close {
	display: inline-block;
	position: absolute;
	right: 40px;
	top: 50%;
	height: 40px;
	width: 40px;
	font-size: 26px;
	text-align: center;
	line-height: 40px;
	color: #fff;
	text-decoration: none;
	-webkit-transform: translateY(-50%) scale(0);
	-moz-transform: translateY(-50%) scale(0);
	-ms-transform: translateY(-50%) scale(0);
	-o-transform: translateY(-50%) scale(0);
	transform: translateY(-50%) scale(0);
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

.main-search.is-visible .btn-search-close {
	-webkit-transform: translateY(-50%) scale(1);
	-moz-transform: translateY(-50%) scale(1);
	-ms-transform: translateY(-50%) scale(1);
	-o-transform: translateY(-50%) scale(1);
	transform: translateY(-50%) scale(1)
}

.main-search.is-visible {
	opacity: 1;
	visibility: visible
}

.animate-search .main-search.is-visible {
	-webkit-animation: cd-slide-in .3s;
	-moz-animation: cd-slide-in .3s;
	animation: cd-slide-in .3s
}

.twoo-col {
	display: table;
	height: 100%;
	margin: 0 auto;
	vertical-align: middle;
	width: 100%
}

.twoo-col .col-right ul {
	font-size: 0;
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: left;
	vertical-align: text-top
}

.twoo-col .col-right ul li {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	vertical-align: text-top;
	margin-right: 30px;
	width: 50%;
	max-width: 240px
}

.twoo-col .col-right .pic-wrapper {
	display: block;
	position: relative;
	background-color: #e9e9e9;
	overflow: hidden;
	width: 240px
}

#main_nav .sub-menu .twoo-col .col-right li a {
	padding: 0;
	line-height: 0
}

.image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	max-width: 240px;
	height: 140px;
	background-color: #e9e9e9;
	overflow: hidden;
	display: block;
	position: relative;
	-webkit-transform: perspective(1000px) translate3d(0, 0, 0);
	transform: perspective(1000px) translate3d(0, 0, 0);
	-webkit-transition: all 1.5s ease 0s;
	transition: all 1.5s ease 0s
}

.image:hover {
	opacity: .6;
	-webkit-transform: perspective(1000px) translate3d(0, 0, 150px);
	transform: perspective(1000px) translate3d(0, 0, 150px)
}

.twoo-col .col-right .blue {
	font-family: Arial, sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 1.58;
	letter-spacing: normal;
	color: #084e8a;
	padding: 13px 0 0;
	display: block
}

.twoo-col .col-right .blue:hover {
	color: #ed174c
}

.banner {
	display: table;
	width: 100%;
	height: 100vh;
	margin: 0 auto;
	padding: 0
}

.banner .b-left {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
	position: relative;
	width: 70%
}

.banner .b-right {
	display: table-cell;
	vertical-align: text-top;
	text-align: right;
	padding: 54px 49px;
	background-color: #4a4a4a
}

.banner-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 121px;
	opacity: .71;
	background-color: #000;
	height: 256px;
	display: table;
	margin-right: 200px
}

.vertical-align {
	display: table-cell;
	vertical-align: middle;
	padding: 0 24px 0 108px
}

.b-image {
	max-width: 600px;
	height: 399px
}

.banner .pic-wrapper {
	display: block;
	position: relative;
	background-color: #e9e9e9;
	overflow: hidden
}

.banner .date {
	font-family: wpplight, Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: normal;
	color: #fff;
	display: block
}

.banner h2 {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 1.78;
	letter-spacing: normal;
	color: #fff;
	display: block;
	text-transform: uppercase;
	width: 71%
}

ul {
	font-size: 0;
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: left;
	vertical-align: text-top
}

ul li {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	vertical-align: text-top
}

h1 {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	line-height: 1.78;
	letter-spacing: normal;
	color: #fff
}

.b-right ul li {
	padding: 0 0 74px
}

.b-right h1 {
	font-size: 18px;
	text-transform: uppercase
}

.b-right h1:hover {
	color: #ccc;
	cursor: pointer
}

.b-right .date {
	padding-bottom: 16px
}

.wrapper.news {
	padding-top: 40px;
	padding-bottom: 44px
}

a.news {
	font-family: wppregular, Arial, sans-serif;
	text-transform: uppercase;
	padding: 0;
	font-size: 18px;
	font-weight: bolder;
	font-style: normal;
	color: #4a4a4a
}

a.news:hover {
	color: #c60e3b
}

.news ul li {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	padding: 0 26px;
	vertical-align: text-top;
	width: 25%
}

.news ul li a,
.annual-report.tbl ul li a {
	text-decoration: none;
	display: block;
	text-align: left
}

.news .image {
	width: 100%;
	max-width: 100%;
	height: 200px
}

.news .info,
.annual-report.tbl .info {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #084e8a;
	padding: 20px 0 0;
	display: block
}

.news .info:hover {
	color: #c60e3b
}

.news .date {
	font-family: wpp medium, Arial, sans-serif;
	font-size: 14px;
	font-weight: 500;
	color: #4a4a4a;
	padding: 0 0 30px;
	display: block
}

.pic-wrapper {
	display: block;
	background-color: #000;
	overflow: hidden
}

.news ul li:first-child {
	padding-left: 0
}

.news ul {
	padding: 93px 0 0
}

.annual-report {
	background-color: #e0f5f5;
	display: block;
	height: auto;
	padding: 60px 0 46px
}

.tbl {
	display: table;
	width: 100%;
	position: relative
}

.annual-report.tbl .col.left {
	font-size: 0
}

.col {
	display: table-cell;
	vertical-align: top
}

.annual-report.tbl .col.right {
	width: 30%;
	text-align: right;
	padding-left: 220px
}

.annual-report.tbl .col.left ul li {
	vertical-align: top;
	display: inline-block;
	width: 25%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 15px
}

.annual-report.tbl .col.left .image {
	height: 199px;
	max-width: 144px
}

.annual-report.tbl .pic-wrapper {
	width: 144px
}

.annual-report.tbl .info {
	font-size: 12px;
	padding: 28px 0 12px;
	display: block
}

.size {
	font-family: wppregular, Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #4a4a4a
}

.annual-report.tbl h3 {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 18px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #272460;
	display: block;
	padding: 0 0 31px;
	text-align: left
}

.annual-report.tbl .col.left ul li:nth-child(1) {
	padding-left: 0
}

.annual-report.tbl .col.left ul li a {
	max-width: 144px
}

.annual-report.tbl .col.right ul li a {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: normal;
	color: #4a4a4a;
	display: inline-block;
	padding: 10px 0 10px 0
}

.annual-report.tbl .col.right .icon {
	text-decoration: none;
	color: #084e8a;
	vertical-align: middle;
	display: inline-block
}

span.text {
	padding-left: 10px;
	display: inline-block
}

.annual-report.tbl .info:hover {
	color: #c60e3b
}

.social-media {
	padding-top: 53px;
	padding-bottom: 0;
	margin-bottom: 0
}

.social-media h4 {
	font-family: wpp medium, Arial, sans-serif;
	font-size: 12px;
	font-weight: 700;
	font-style: normal;
	color: #084e8a;
	padding: 0 0 50px
}

.social-media ul li {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	padding: 0 12px 0;
	vertical-align: text-top;
	width: 25%
}

.logos {
	display: table;
	height: 100%;
	margin: 0 auto;
	vertical-align: middle;
	width: 100%
}

.lefty {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	padding: 0
}

.righty {
	display: table-cell;
	vertical-align: middle;
	text-align: right;
	padding: 0
}

.social-media .tbl {
	padding-bottom: 30px;
	border-bottom: 2px solid #4a4a4a
}

.social-media .left,
.social-media .right {
	padding-bottom: 36px
}

.socl {
	display: block;
	max-width: 60px;
	max-height: 60px;
	vertical-align: middle;
	padding: 0;
	margin: 0
}

.name {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 11px;
	font-weight: 700;
	letter-spacing: normal;
	color: #272460;
	display: block;
	text-align: left;
	text-transform: uppercase
}

.tag {
	font-size: 12px;
	font-weight: 500;
	text-transform: lowercase;
	display: block;
	text-align: left
}

.social-media ul li:first-child {
	padding-left: 0
}

.social-media .lefty {
	width: 60px
}

.social-media .righty {
	padding-left: 13px
}

.content {
	padding: 27px 0 40px;
	display: block
}

.social-media p {
	font-family: wpplight, Arial, sans-serif;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
	color: #4a4a4a
}

.article-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	display: block;
	width: 100%;
	min-height: 160px
}

.article-image img {
	border-radius: 4px
}

.article-image :hover {
	background-color: #fff;
	-webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 16px rgba(0, 0, 0, .3);
	border-color: transparent
}

.content a {
	color: #4a4a4a;
	text-decoration: underline;
	font-family: wpplight, Arial, sans-serif
}

.foot {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	width: 100%;
	min-height: 397px;
	position: relative
}

.foot:before {
	background-color: rgba(36, 36, 96, .8);
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%
}

.foot {
	display: block;
	padding: 0 108px
}

.footer .lefty {
	padding-right: 104px;
	width: 398px
}

.footer .righty {
	font-size: 0;
	padding-top: 40px
}

.tbl.footer .righty {
	vertical-align: top !important
}

.footer .lefty {
	vertical-align: top !important
}

.footer .block,
.coloured-strip .block {
	vertical-align: top;
	display: inline-block;
	width: 33.33%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 20px
}

.footer .block h3 {
	font-family: wppbold, Arial, sans-serif;
	font-size: 15px;
	line-height: 1.69;
	letter-spacing: normal;
	color: #fff;
	display: block;
	text-transform: uppercase;
	text-align: left;
	margin: 0 0 10px;
	font-weight: 700
}

.footer .block ul {
	list-style: none;
	padding: 0;
	margin: 0
}

.footer .block ul li a {
	font-family: wppregular, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.69;
	letter-spacing: normal;
	color: #fff;
	display: block
}

.footer .block ul li a:hover {
	color: #c60e3b
}

.footer .intro {
	display: block;
	padding: 39px 0 25px;
	font-size: 18px;
	line-height: 1.5;
	color: #4a4a4a
}

.intro p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 18px;
	line-height: 27px;
	color: #fff
}

.footer .lefty .social-media-icons {
	display: inline-block
}

.footer .lefty .social-media-icons .icon {
	font-family: wppregular, Arial, sans-serif;
	color: #fff;
	display: inline-block;
	font-size: 25px;
	padding: 0 24px 0 0;
	text-decoration: none;
	vertical-align: middle
}

.footer .lefty .social-media-icons .icon:hover {
	color: #c60e3b
}

.footer .block:first-child {
	padding-left: 0
}

.footer .block:last-child {
	padding-right: 0
}

.tbl.footer {
	margin-bottom: 0;
	padding-bottom: 30px
}

.logo-strip.tbl {
	border-top: solid 0 #979797;
	padding-top: 34px
}

.thin-line {
	border-top: solid 2px #979797;
	margin-top: 80px;
	padding-bottom: 0
}

.coloured-strip .block {
	width: 25%;
	padding: 0;
	height: 5px
}

.coloured-strip .blue {
	background-color: #64c3d7;
	display: none
}

.coloured-strip .yello {
	background-color: #fece39;
	display: none
}

.coloured-strip .pink {
	background-color: #e73580;
	display: none
}

.coloured-strip .green {
	background-color: #005a57;
	display: none
}

.logo-strip.tbl .lefty {
	padding-right: 48px;
	width: 335px
}

.logo-strip.tbl .righty {
	font-size: 0;
	padding-left: 35px
}

.coloured-strip.tbl {
	display: block;
	border-bottom: 1px solid #fff
}

.ft-logo {
	display: block;
	max-width: 290px;
	vertical-align: middle;
	padding: 0;
	margin: 0;
	z-index: 20
}

h5 {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 42px;
	font-weight: 700;
	line-height: 64px;
	letter-spacing: normal;
	color: #272460;
	text-align: center;
	display: block;
	padding-top: 0
}

h5 span {
	font-size: 18px;
	line-height: 64px;
	text-align: left;
	display: block;
	padding-top: 0;
	text-transform: uppercase
}

.wrap li {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	padding: 40px 0;
	vertical-align: text-top;
	width: 100%;
	border-bottom: solid 1px #979797
}

.wrap li:first-child {
	border-top: solid 1px #979797
}

.l-side {
	display: table-cell;
	vertical-align: top;
	text-align: left;
	padding: 0 142px 0 0;
	width: 260px;
	font-family: wppregular, Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: 3.56;
	letter-spacing: normal;
	color: #9b9b9b
}

.r-side {
	display: table-cell;
	vertical-align: top;
	text-align: left;
	padding: 0;
	width: auto;
	font-family: wpp bold, Arial, sans-serif;
	font-size: 36px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.78;
	letter-spacing: normal;
	color: #084e8a
}

.pagination .icon {
	font-size: 15px;
	color: #31373d
}

ul.pagination {
	display: block;
	padding: 0;
	margin: 42px 0;
	font-size: 18px;
	text-align: center
}

ul.pagination li {
	vertical-align: top;
	display: inline-block
}

ul.pagination li a {
	color: #272460;
	float: left;
	padding: 0;
	text-decoration: none;
	font-family: wppblack, Arial, sans-serif;
	font-size: 18px;
	width: 40px;
	height: 40px;
	line-height: 40px
}

ul.pagination li a.active {
	background-color: #084e8a;
	color: #fff;
	border-radius: 50%
}

ul.pagination li a:hover:not(.active) {
	background-color: #ddd;
	border-radius: 50%
}

.container {
	position: relative;
	margin-bottom: 50px
}

.container-b {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	width: 100%;
	min-height: 550px;
	position: relative
}

.container-b:before {
	opacity: .73;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%
}

.text-block {
	position: absolute;
	bottom: 70px;
	left: 120px;
	width: 100%
}

.text-block p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 25px;
	line-height: 1.28;
	color: #fff;
	width: 37%
}

.text-block h6 {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 56px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #fff;
	display: block;
	padding: 0 0 25px
}

.careers {
	padding-bottom: 27px;
	padding-top: 50px
}

.careers.tbl {}

.careers.tbl:nth-child(even),
.atglance.tbl:nth-child(even) {
	direction: rtl
}

.careers .lefty,
.atglance .lefty {
	padding: 0;
	direction: ltr
}

.careers .drctn,
.drctn {
	padding: 20px 20px 50px 60px
}

.policy.drctn {
	padding-bottom: 130px
}

.careers h4,
.careers a.h4,
.drctn h4,
.policy a.h4,
.atglance.tbl a.h4 {
	font-family: wppbold, Arial, sans-serif;
	font-size: 42px;
	font-weight: 700;
	line-height: 1.31;
	letter-spacing: normal;
	color: #084e8a;
	display: block;
	padding-bottom: 30px;
	text-align: left
}

.careers h4,
.careers a.h4 {
	font-size: 18px;
	line-height: 1.28;
	color: #084e8a;
	padding-bottom: 20px
}

.careers a.h4:hover {
	color: #c60e3b
}

.atglance.tbl a.h4:hover {
	color: #c60e3b
}

.policy.drctn a.h4:hover {
	color: #c60e3b
}

.policy .drctn h4 {
	padding-bottom: 10px
}

.careers p,
.drctn p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 25px;
	color: #454f5b;
	display: block;
	padding-bottom: 25px;
	line-height: 1.28
}

.careers p {
	font-size: 14px;
	color: #454f5b;
	padding-bottom: 20px;
	line-height: 2.29
}

.policy.drctn p {
	width: 50%
}

a.icn {
	font-family: wppregular, Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: normal;
	color: #454f5b;
	display: inline-block
}

a.icn:hover {
	color: #c60e3b
}

.careers a.icn:hover:before {
	color: #c60e3b
}

.careers .bn {
	font-family: wppregular, Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: normal;
	color: #454f5b
}

.icn:before {
	content: "\e903";
    display: block;
	position: absolute;
	overflow: hidden;
	font-family: icomoon !important;
	width: 20px;
	height: 20px;
	position: relative;
	top: 0;
	font-size: 15px;
	margin-right: 10px;
	color: #31373d
}

.careers .righty {
	width: 70%;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	height: auto;
	position: relative
}

.careers .image,
.atglance.tbl .image {
	max-width: 100%;
	height: 320px
}

.careers .image {
	height: 350px
}

.atglance.tbl .image {
	height: 400px
}

.blog .text-block {
	bottom: 45px
}

.text p {
	font-size: 28px;
	margin-bottom: 30px;
	line-height: 1.5;
	font-family: wppregular, Arial, sans-serif
}

.text h4 {
	font-family: wppbold, Arial, sans-serif;
	font-size: 45px;
	line-height: 1.19;
	letter-spacing: normal;
	color: #084e8a;
	padding: 50px 0
}

.blog .lefty {
	width: 50%
}

.stats .col.left {
	width: 50%;
	text-align: left;
	padding-right: 56px;
	padding-left: 56px;
	text-align: center
}

.stats .col.right {
	padding-left: 56px;
	padding-right: 56px;
	text-align: center
}

.blog .tbl h4 {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 153.6px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #084e8a;
	display: block;
	padding: 20px 0
}

.tbl.stats h4,
.stats p {
	color: #fff
}

.blog {}

.blog .tbl span {
	font-family: wpplight, Arial, sans-serif;
	font-size: 153.6px;
	font-weight: 300
}

.blog .tbl p {
	font-size: 24px;
	text-align: center;
	line-height: 28px
}

.blog .tbl .righty p {
	text-align: right
}

.padding {
	padding: 40px 0 78px
}

.pics .text h4 {
	text-transform: uppercase;
	font-size: 24px
}

.pics .text {
	border-top: solid 1px #979797
}

.tbl.pics {
	height: 400px;
	padding-bottom: 73px
}

.tbl.pics.revolution {
	height: 453px;
	padding-bottom: 40px
}

.tbl.pics.revolution .lefty {
	padding-right: 50px
}

.tbl.pics.revolution .recognition {
	background-position: center
}

p.b-cap {
	font-size: 16px
}

.tbl.pics .lefty,
.tbl.pics .righty {
	background-repeat: no-repeat;
	background-size: contain
}

.tbl.pics .lefty {
	width: 50%;
	border-right: 1px solid #fff
}

.row-tbl {
	display: table;
	width: 100%;
	height: 100%
}

.row {
	display: table-row
}

.row-col {
	display: table-cell;
	border-bottom: 1px solid #fff
}

.row-col:first-child {
	border-top: 0 solid #fff
}

.recognition {
	display: block;
	height: 100%;
	background-size: cover;
	background-position: top;
	background-repeat: no-repeat;
	position: relative
}

.recognition .ttle {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 25.2px;
	font-weight: 700;
	line-height: 1.14;
	color: #fff;
	padding-bottom: 7px;
	text-transform: uppercase;
	max-width: 50%;
	text-align: left;
	display: block
}

.tbl.pics .righty .recognition .ttle {
	font-size: 18.2px
}

.tbl.pics .righty .cont-wrap {
	bottom: 22px
}

.recognition .small-text {
	font-family: wppregular, Arial, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.62;
	letter-spacing: normal;
	color: #fff;
	padding-top: 0;
	margin-top: 0;
	display: block;
	text-align: left
}

a.recognition:before {
	opacity: .5;
	background-color: rgba(0, 0, 0, .5);
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	-webkit-transition: all .3s ease;
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-ms-transition: all .3s ease;
	transition: all .3s ease
}

a.recognition:hover:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(170, 77, 2, .4);
	height: 100%;
	width: 100%
}

.cont-wrap {
	display: table;
	position: absolute;
	bottom: 40px;
	left: 40px;
	padding: 0;
	width: 100%;
	z-index: 20;
	box-sizing: border-box
}

.glance h5 {
	font-size: 58px
}

.glance h5 span {
	font-size: 24px;
	font-weight: 300;
	font-style: normal;
	line-height: 1.33;
	text-align: center;
	display: block;
	padding: 20px 0 30px;
	text-transform: none;
	font-family: wpplight, Arial, sans-serif
}

.figures {
	font-family: wppregular, Arial, sans-serif;
	font-size: 24px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #084e8a;
	padding: 0;
	display: block
}

.figures span {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 72px;
	font-weight: 700;
	font-style: normal;
	line-height: .81;
	letter-spacing: normal;
	text-align: center;
	color: #272460;
	padding: 22px 0 84px;
	display: block
}

.glance-2.padding {
	padding: 60px 0
}

.atglance .lefty {
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	background-position: right 20px bottom 10px;
	height: auto;
	position: relative;
	height: 410px
}

.atglance .righty {
	width: 50%;
	padding: 0
}

a.icn {
	font-family: wppmedium, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: normal;
	color: #454f5b;
	text-align: left;
	display: block
}

.atglance .drctn {
	padding: 20px 230px 50px 50px
}

.atglance.tbl {
	padding-bottom: 60px
}

.glance li a {
	font-family: wpplight, Arial, sans-serif;
	font-size: 32px;
	font-weight: 300;
	padding: 38px 0;
	text-align: left;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #084e8a
}

span.icn {
	font-family: wpp medium, Arial, sans-serif;
	font-size: 17px;
	font-weight: 700;
	line-height: 33px;
	letter-spacing: normal;
	color: #454f5b;
	text-align: left;
	display: block;
	padding: 28px 0 0
}

span.icn:before {
	content: "\e903";
	display: inline-block;
	position: absolute;
	overflow: hidden;
	font-family: icomoon !important;
	width: 20px;
	height: 20px;
	position: relative;
	top: 0;
	font-size: 10px;
	margin-right: 10px;
	color: #454f5b
}

.glance a:hover,
.glance a:hover>span,
.glance a:hover>span:before {
	color: #c60e3b
}

.glance .wrap li {
	padding: 45px 0
}

.homepage {
	display: block;
	width: 100%
}

.homepage .table {
	display: table;
	width: 100%;
	height: 600px;
	position: relative
}

.homepage .tcell-left {
	text-align: left;
	display: table-cell;
	vertical-align: text-top;
	width: 33.33%;
	padding: 65px 24px 164px 49px;
	background-color: #fff
}

.homepage .tcell-right {
	text-align: right;
	display: table-cell;
	vertical-align: text-top;
	padding: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 727px;
	position: relative
}

.homepage h1,
.searchy h1 {
	font-family: wppblack, Arial, sans-serif;
	font-size: 24px;
	font-weight: 900;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #084e8a;
	padding-bottom: 28px;
	text-transform: uppercase
}

.searchy h1 {
	text-transform: uppercase;
	padding-bottom: 20px
}

.homepage p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.83;
	letter-spacing: normal;
	color: #4a4a4a
}

.homepage a.arrowright {
	font-family: wppblack, Arial, sans-serif;
	font-size: 12px;
	font-weight: 900;
	font-style: normal;
	line-height: 2.25;
	letter-spacing: normal;
	color: #084e8a;
	text-transform: uppercase;
	text-align: left
}

.homepage .arrowright:after {
	content: "\e903";
	display: inline-block;
	font-family: icomoon !important;
	width: 15px;
	height: 15px;
	position: relative;
	top: 0;
	font-size: 8px;
	margin-left: 10px;
	color: #084e8a
}

a.arrowright:hover {
	color: #c60e3b
}

.homepage .image {
	max-width: 100%;
	height: 727px
}

.stats {
	position: relative;
	display: table;
	width: 100%;
	position: relative
}

.stats .col-1 {
	text-align: left;
	display: table-cell;
	vertical-align: text-top;
	width: 66.67%;
	padding: 65px 0 64px;
	position: relative
}

.stats .col-2 {
	text-align: left;
	display: table-cell;
	vertical-align: middle;
	position: relative
}

.homepage .stats .col-1 .video .image {
	max-width: 100%;
	height: 100%
}

.featured-work {
	position: absolute;
	left: 0;
	font-size: 0;
	bottom: -49px;
	width: 100%
}

.stats .video {
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	height: 527px;
	width: 50%;
	display: inline-block;
	vertical-align: top
}

.stats .statistics {
	background-color: #6a306e;
	padding: 25px;
	/* padding: 59px 34px 59px 25px; */
	width: 50%;
	display: inline-block;
	vertical-align: top
}

.stats .story-chela {
	padding: 40px 30px
}

.video .film:before {
	content: '';
	display: table;
	position: absolute;
	top: 0;
	left: 0;
	text-align: center;
	height: 100%;
	width: 100%;
	z-index: 1;
	box-sizing: border-box
}

.video .icon-button {
	color: #fff;
	display: block;
	font-size: 107px;
	font-weight: 400;
	text-decoration: none;
	text-align: center;
	height: 100px;
	vertical-align: middle;
	width: 100px;
	line-height: 100px
}

span.icon-button>a:hover:before {
	color: red
}

.new-accord>li:hover .title {
	background-color: transparent;
	color: #ed174c
}

.pic-wrapper>a {
	width: 100%;
	display: block;
	height: 100%
}

.video span {
	display: flex;
	position: relative;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 5
}

a.video {
	text-decoration: none;
	display: block;
	text-align: center
}

.stats .statistics h1 {
	font-size: 28px;
	color: #fff;
	text-transform: uppercase;
	padding-bottom: 7px
}

.homepage .statistics p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.83;
	letter-spacing: normal;
	color: #fff
}

.statistics .tbl {
	padding: 0 37px 0 0
}

.statistics .lefty,
.statistics .righty {
	font-family: wppbold, Arial, sans-serif;
	font-size: 61px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
	padding-right: 37px
}

.statistics .righty {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	padding-right: 0;
	width: 50%
}

.chela-01 {
	font-family: wppregular, Arial, sans-serif;
	font-size: 28px;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: .96;
	letter-spacing: normal;
	color: #084e8a;
	display: block
}

.story {
	font-family: wppblack, Arial, sans-serif;
	font-size: 28px;
	font-weight: 900;
	font-style: normal;
	line-height: .96;
	letter-spacing: normal;
	color: #084e8a;
	display: block;
	padding: 14px 0
}

.client {
	font-family: wppregular, Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #084e8a
}

.statistics .lefty span,
.statistics .righty span {
	font-family: wpplight, Arial, sans-serif;
	font-size: 61px;
	font-weight: 300;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff
}

.statistics .lefty .desc,
.statistics .righty .desc {
	font-family: wppbold, Arial, sans-serif;
	font-size: 12px;
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
	display: block
}

.stories {
	position: absolute;
	top: 100px;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%
}

.s-arrow {
	text-decoration: none;
	color: #fff;
	font-size: 18px;
	font-weight: 400;
	vertical-align: middle;
	display: block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	padding: 0;
	text-align: center;
	border: 0 solid #949494;
	background-color: #31373d;
	border-radius: 100%
}

.s-arrow:hover {
	background-color: #6a306e
}

.owl-theme .owl-nav [class*=owl-]:hover {
	background-color: transparent !important
}

.owl-pagination {
	display: none
}

.customNavigation a {
	color: #fff
}

.customNavigation .btn {
	color: #fff;
	font-size: 18px;
	font-weight: 400;
	vertical-align: middle;
	display: inline-block;
	width: 18px;
	height: 18px;
	line-height: 18px;
	padding: 0;
	text-align: center;
	margin: 0 auto;
	border: 0 solid #949494;
	background-color: #31373d;
	border-radius: 100%;
	margin-right: 27px
}

.owl-carousel .owl-item img {
	margin: 0 auto
}

.customNavigation {
	position: absolute;
	right: 76px;
	bottom: 40px
}

.customNavigation button.owl-next,
button.owl-prev {
	border: none;
	padding: 0 13px;
	background: 0 0
}

.customDots {
	position: absolute;
	top: 3px;
	right: 30px
}

.owl-carousel button.owl-dot {
	display: inline-block;
	width: 4px;
	height: 4px;
	margin: 5px;
	border-radius: 50%;
	border: none;
	background: #d8d8d8 !important
}

.owl-carousel button.owl-dot:hover {
	background: #4a4a4a !important
}

button.owl-dot.active {
	background: #4a4a4a !important;
	width: 5px;
	height: 5px
}

.wpp-bg {
	position: relative;
	width: 100%
}

.gradient {
	width: 100%;
	height: 100vh;
	object-fit: cover;
	position: relative;
	z-index: 2;
	opacity: .69;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000)
}

.wpp-bg .video {
	position: absolute;
	top: 0;
	z-index: -1;
	width: 100%;
	height: 100vh;
	min-height: 650px;
	-o-object-fit: cover;
	object-fit: cover
}

.wpp-bg .video {
	height: 100vh
}

@supports(object-fit:cover) {
	.wpp-bg .video video {
		height: 100vh
	}
}

@supports(object-fit:cover) {
	.fullwidth-video video {
		height: 100vh
	}
}

.caption-menu {
	position: absolute;
	top: 25%;
	left: 131px;
	width: 24%;
	z-index: 2
}

.proin {
	font-family: wppmedium, Arial, sans-serif;
	font-size: 35px;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #fff;
	display: block
}

.caption-menu p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	color: #fff;
	padding: 43px 0 47px
}

.caption-menu ul {
	font-size: unset;
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: left;
	vertical-align: text-top;
	display: inline-block
}

.caption-menu ul li {
	position: relative;
	padding-left: 15px;
	margin-bottom: 5px
}

.caption-menu>ul>li>a {
	display: inline-block;
	position: relative;
	color: #637381;
	font-size: 20px;
	font-weight: 400;
	font-style: normal;
	line-height: 3.2;
	padding: 0 0 0 36px;
	letter-spacing: normal;
	cursor: pointer;
	font-family: wppregular, Arial, sans-serif
}

.caption-menu>ul>li>a span {
	display: block;
	position: relative
}

.caption-menu>ul>li>a span:after {
	content: "";
	height: 2px;
	width: 100%;
	max-width: 0;
	position: absolute;
	left: 0;
	bottom: 8px;
	background-color: #fff;
	-webkit-transition: max-width .5s;
	-moz-transition: max-width .5s;
	transition: max-width .5s;
	overflow: hidden
}

.caption-menu>ul>li>a:hover {
	color: #fff
}

.caption-menu>ul>li>a:hover span:after {
	max-width: 100%
}

.caption-menu>ul>li>a:hover:before {
	color: #fff
}

.caption-menu ul>li>a:before {
	content: "\e903";
	display: block;
	font-size: 17px;
	height: 0;
	width: 0;
	color: #637381;
	position: absolute;
	left: 0;
	top: 4px;
	font-family: icomoon
}

.wrapper.about-us {
	padding-bottom: 50px
}

.inside-banners {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
	width: 100%;
	min-height: 350px;
	margin: 0 0 58px;
	position: relative
}

.blg {
	min-height: 500px;
	margin-top: 52px
}

.inside-banners.blg:before {
	background-color: transparent
}

.inside-banners:before {
	opacity: .8;
	background-color: #07043d;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%
}

.banners-cption {
	position: absolute;
	bottom: 66px;
	left: 290px;
	width: 100%;
	margin: 0 30px 0 0
}

.banners-cption h1,
.leader h1 {
	font-family: wppbold, Arial, sans-serif;
	display: block;
	padding: 0;
	font-size: 36px;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
	padding: 25px 0 0;
	position: relative;
	text-transform: uppercase
}

.leader h1 {
	padding: 25px 0
}

.banners-cption h1:before,
h2.projects:before,
.leader h1:before {
	content: "";
	height: 2px;
	width: 53px;
	display: block;
	background-color: #ed174c;
	position: absolute;
	top: 0;
	left: 0
}

.leader h1 {
	color: #17154c
}

.leader h1 span {
	font-size: 17px;
	display: block;
	padding: 10px 0 0;
	color: #ed174c
}

.column {
	display: table-cell;
	vertical-align: middle;
	width: 50%
}

.leader .righty {
	text-align: left;
	width: 50%;
	padding-left: 40px;
	font-family: wppregular, Arial, sans-serif;
	font-size: 20px;
	line-height: 1.6;
	color: #4a4a4a;
	vertical-align: top
}

#breadcrumbs {
	display: block;
	padding: 26px 0 0
}

#breadcrumbs ul {
	font-size: 0
}

.transparent-menu header .top-bar {
	background-color: transparent
}

header.animate-search.scrolling {
	opacity: 1;
	background-color: #07043d;
	width: 100%;
	line-height: 66px
}

#breadcrumbs li {
	display: inline-block;
	vertical-align: middle;
	margin: 0 3px;
	font-size: 12px;
	font-family: wppbold, Arial, sans-serif;
	padding: 0 14px 0 0;
	line-height: 58px;
	position: relative;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #ed174c;
	text-transform: uppercase
}

#breadcrumbs li:first-child {
	margin-left: 0
}

#breadcrumbs li:after {
	content: "/";
	position: absolute;
	right: 0;
	top: 0
}

#breadcrumbs li:last-child:after {
	content: ""
}

#breadcrumbs li.active a {
	pointer-events: none;
	color: #fff
}

#breadcrumbs a {
	display: block;
	color: #ed174c;
	font-family: wppbold, Arial, sans-serif
}

.about-us h2,
.company h2,
h2.projects {
	font-family: wppbold, Arial, sans-serif;
	display: block;
	padding: 0;
	font-size: 36px;
	line-height: 1.78;
	letter-spacing: normal;
	color: #084e8a;
	padding: 30px 0 40px;
	text-transform: uppercase
}

.wrapper.about-us img {
	max-width: 40%;
	margin: 10px auto
}

h2.projects {
	text-transform: capitalize;
	position: relative;
	padding: 11px 0 40px
}

.about-us p,
.company p,
.about-us {
	font-size: 24px;
	font-weight: 400;
	line-height: 1.75;
	letter-spacing: normal;
	color: #4a4a4a;
	margin: 0;
	font-family: wppregular, Arial, sans-serif
}

.about-us {
	margin: 0 auto
}

.about-us ul,
.item-page ul,
li ul li,
ul.list-styled {
	margin: 20px 0 20px 15px
}

.about-us ul li {
	list-style: disc;
	margin: 14px 0;
	font-family: wppregular, Arial, sans-serif;
	font-size: 24px;
	line-height: 26px;
	display: list-item;
	color: #4a4a4a
}

li,
p {
	margin-bottom: 10px
}

.companies {
	display: block;
	padding: 55px 0 40px
}

.companies .block {
	vertical-align: top;
	display: inline-block;
	width: calc(25% - 40px);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 40px;
	margin: 40px 0
}

.companies .block:first-child {
	padding-left: 0
}

.companies .block:nth-child(5) {
	padding-left: 0
}

.companies .block ul {
	list-style: none;
	padding: 0;
	margin: 0
}

.companies .block ul li a {
	font-family: wppbold, Arial, sans-serif;
	font-size: 21px;
	font-weight: 700;
	font-style: normal;
	line-height: 73px;
	color: #9b9b9b;
	padding: 10px 0;
	display: block;
	border-bottom: dashed 1.7px #979797;
	position: relative
}

.companies .block h7 {
	font-family: wppbold, Arial, sans-serif;
	display: block;
	font-size: 34px;
	font-weight: 700;
	font-style: normal;
	line-height: normal;
	color: #084e8a;
	padding: 0 0 25px;
	min-height: 111px;
	max-width: 180px
}

.companies .block ul li a:after {
	content: "\e908";
	font-family: icomoon;
	position: absolute;
	color: #31373d;
	font-size: 25px;
	right: 0
}

.companies .block ul li a:hover {
	color: #ed174c;
	border-bottom: solid 1.7px #979797
}

.companies .block>ul>li>a:hover:after {
	color: #ed174c
}

.related-projects {
	display: block;
	padding: 70px 0 0
}

.wrap {}

.slider-2.owl-carousel.owl-loaded.owl-drag button.owl-dot {
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: 0 0 !important;
	border: solid 1px #4a4a4a;
	-webkit-transition: all .2s ease;
	-moz-transition: all .2s ease;
	-o-transition: all .2s ease;
	transition: all .2s ease;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1)
}

.slider-2.owl-carousel.owl-loaded.owl-drag button.owl-dot.active {
	background-color: #ed174c !important;
	border: solid 1px #fff;
	transform: scale(1.6)
}

.slider-2 .owl-dots {
	margin: 20px auto 0;
	display: block !important;
	text-align: center
}

.hvrbox .hvrbox-layer_top {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .6);
	color: #fff;
	padding: 15px;
	-moz-transition: all .4s ease-in-out 0s;
	-webkit-transition: all .4s ease-in-out 0s;
	-ms-transition: all .4s ease-in-out 0s;
	transition: all .4s ease-in-out 0s
}

.hvrbox .hvrbox-text {
	text-align: center;
	font-size: 18px;
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80%;
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

.hvrbox .hvrbox-text p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 13px;
	font-weight: 400;
	letter-spacing: normal;
	text-align: center;
	color: #fff;
	display: block
}

.hvrbox .hvrbox-text h3.heading {
	font-family: wppbold, Arial, sans-serif;
	font-size: 18px;
	font-style: normal;
	letter-spacing: normal;
	color: #fff;
	display: block;
	padding: 0 0 15px
}

.hvrbox .hvrbox-layer_slideup {
	-moz-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%)
}

.hvrbox .hvrbox-layer_top {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .5);
	color: #fff;
	padding: 15px;
	-moz-transition: all .4s ease-in-out 0s;
	-webkit-transition: all .4s ease-in-out 0s;
	-ms-transition: all .4s ease-in-out 0s;
	transition: all .4s ease-in-out 0s
}

.hvrbox,
.hvrbox * {
	box-sizing: border-box
}

.slider-2 .owl-item {
	display: block;
	width: 355px;
	height: 407px
}

.hvrbox {
	position: relative;
	display: inherit;
	overflow: hidden;
	width: 100%;
	height: 100%
}

.hvrbox.hvrbox-layer_bottom {
	display: block;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100% !important;
	position: relative
}

.hvrbox img {
	max-width: 100% !important;
	width: 100% !important;
	margin: 0 !important
}

.hvrbox:hover .hvrbox-layer_slideup,
.hvrbox.active .hvrbox-layer_slideup {
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0)
}

.hvrbox:hover .hvrbox-layer_top,
.hvrbox.active .hvrbox-layer_top {
	opacity: 1
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: 0 0
}

ul.accordion {
	list-style-type: none !important
}

.accordion>li {
	background-color: transparent;
	margin: 0;
	border: none;
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s;
	border-bottom: 2px solid #d8d8d8
}

.accordion .title {
	background-color: transparent;
	display: block;
	padding: 15px 60px 15px 15px;
	position: relative
}

.new-accord .title {
	background-color: transparent;
	display: block;
	padding: 15px 60px 15px 15px;
	position: relative
}

.accordion .title {
	color: #fff;
	font-family: wppmedium, Arial, sans-serif;
	font-size: 28px;
	font-weight: 500;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	color: #084e8a;
	padding: 30px 0;
	display: block
}

.new-accord .title {
	color: #fff;
	font-family: wppmedium, Arial, sans-serif;
	font-size: 28px;
	font-weight: 500;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	color: #084e8a;
	padding: 30px 0;
	display: block
}

.accordion .title:before {
	z-index: 2
}

.new-accord .title:before {
	z-index: 2
}

.accordion .title:after {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	content: "\e90b";
	font-family: icomoon !important;
	font-size: 18px;
	display: block;
	color: #31373d;
	background-color: transparent;
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	border-radius: 100%;
	font-weight: 400;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

.accordion .content-visible .title:after {
	content: "\e90a";
	-webkit-transform: rotate(-180deg) !important;
	-ms-transform: rotate(-180deg) !important;
	-moz-transform: rotate(-180deg) !important;
	transform: rotate(-180deg) !important
}

.accordion .desc {
	padding: 50px 0 30px;
	display: none
}

.new-accord .desc {
	padding: 50px 0 30px;
	display: none
}

.desc.tbl {}

.accordion>li:hover .title {
	background-color: transparent;
	color: #ed174c
}

.accordion .content-visible {
	background-color: transparent
}

.accordion p {
	font-size: 12px;
	line-height: 16px
}

a.title:hover:after {
	color: red
}

.accordion .column {
	display: inline-block;
	vertical-align: top;
	width: 50%;
	padding: 0 30px 20px 0;
	text-align: left
}

.wrapper.company .accordion .column {
	padding: 0 30px 40px 0
}

.accordion .column:last-child {
	padding-right: 0
}

.accordion .column>a {
	display: inline-block;
	padding-right: 70px
}

.accordion .column:last-child {
	padding-right: 0
}

.accordion .column:first-child {
	padding-right: 0
}

.c-ttle {
	font-family: wppbold, Arial, sans-serif;
	font-size: 30px;
	font-weight: 900;
	font-style: normal;
	line-height: normal;
	color: #084e8a;
	display: block;
	padding: 16px 0 13px
}

p.info {
	font-size: 20px;
	font-weight: 400;
	line-height: 1.75;
	letter-spacing: normal;
	color: #4a4a4a;
	margin: 0;
	font-family: wppregular, Arial, sans-serif;
	display: block
}

.c-logo {
	display: block;
	max-width: 100px;
	vertical-align: middle;
	padding: 0;
	margin: 0
}

.accordion span.icn:before {
	font-size: 7px;
	margin: 0
}

.accordion span.icn {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 400;
	padding: 0
}

.accordion span.icn:hover:before {
	color: #ed174c
}

.accordion span.icn:hover {
	color: #ed174c
}

span.c-ttle:hover {
	color: #ed174c
}

.accordion .column:first-child {
	padding-left: 0
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: 0 0
}

* {
	-webkit-tap-highlight-color: transparent
}

input,
textarea {
	-webkit-border-radius: 0
}

input[type=submit],
input[type=button] {
	-webkit-appearance: none
}

::-webkit-input-placeholder {
	color: #aaa
}

::-moz-placeholder {
	color: #aaa
}

body {
	line-height: 1;
	-webkit-text-size-adjust: none
}

ol,
ul {
	list-style: none
}

blockquote,
q {
	quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none
}

:focus {
	outline: 0
}

ins {
	text-decoration: none
}

del {
	text-decoration: line-through
}

table {
	border-collapse: collapse;
	border-spacing: 0
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

p {
	margin-bottom: 15px;
	line-height: 20px;
	font-size: 24px;
	font-weight: 400;
	line-height: 1.75;
	letter-spacing: normal;
	color: #4a4a4a;
	font-family: wppregular, Arial, sans-serif
}

img {
	max-width: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	border: none;
	display: block
}

.clearfloat {
	zoom: 1
}

.clearfloat:before,
.clearfloat:after {
	content: "";
	display: table
}

.clearfloat:after {
	clear: both
}

html,
body {
	height: 100%;
	width: 100%
}

body {
	background-color: #f3f3f3;
	font-family: Arial, sans-serif;
	font-size: 14px;
	color: #333;
	line-height: 20px
}

body.menu-open {
	background-color: #fff
}

#main_wrapper {
	position: relative;
	width: 100%;
	overflow: hidden;
	height: auto;
	min-height: 100%
}

#content_container {
	width: 100%;
	min-height: 100%;
	position: relative;
	left: 0;
	background-color: #f3f3f3
}

.menu-open #content_mask {
	position: absolute;
	z-index: 50;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, .85)
}

.menu-open #content_container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 0
}

#content_container .content-wrapper {
	padding: 20px
}

#header {
	background-color: #fff;
	width: 100%;
	border-bottom: 1px solid #dcdcdc
}

#header .menu-icon {
	width: 47px;
	height: 47px;
	float: right;
	cursor: pointer
}

#header .icon {
	background: url(/images/menu-arrow.png) 0 0 no-repeat;
	background-size: 700%;
	display: block
}

#header .menu-icon span {
	width: 18px;
	height: 15px;
	margin: 15px auto 0;
	background-position: 0 0
}

.menu-open #header .menu-icon span {
	background-position: -18px 0
}

#header .logo {
	display: block;
	width: 111px;
	margin: 8px auto 0
}

#header .search-icon {
	width: 47px;
	height: 47px;
	float: left;
	cursor: pointer
}

#header .search-icon span {
	width: 20px;
	height: 20px;
	margin: 15px auto 0;
	background-position: -40px 0
}

.search-open #header .search-icon span {
	background-position: -60px 0
}

body:after {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .6);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity .3s 0s, visibility 0s .3s;
	-moz-transition: opacity .3s 0s, visibility 0s .3s;
	transition: opacity .3s 0s, visibility 0s .3s
}

body.menu-open {
	overflow: hidden
}

body.menu-open:after {
	visibility: visible;
	opacity: 1;
	z-index: 2500;
	-webkit-transition: opacity .3s 0s, visibility 0s 0s;
	-moz-transition: opacity .3s 0s, visibility 0s 0s;
	transition: opacity .3s 0s, visibility 0s 0s
}

#mobile_nav_wrapper {
	width: 305px;
	height: 100%;
	padding: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 3000;
	background-color: #1c1f54;
	display: block;
	visibility: hidden;
	-webkit-transition: all .25s linear 0s;
	transition: all .25s linear 0s;
	-webkit-transform: matrix(1, 0, 0, 1, 305, 0);
	transform: matrix(1, 0, 0, 1, 305, 0);
	-webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3)
}

.menu-open #mobile_nav_wrapper {
	display: block;
	overflow-y: auto;
	visibility: visible;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: matrix(1, 0, 0, 1, 0, 0);
	transform: matrix(1, 0, 0, 1, 0, 0)
}

#mobile_nav_wrapper .mobile-nav-content {
	position: relative;
	z-index: 100;
	height: 100%
}

#mobile_nav_wrapper .header {
	background-color: #1c1f54;
	height: 30px;
	padding: 20px;
	position: relative;
	z-index: 200
}

#mobile_nav_wrapper .header .menu-close {
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	position: absolute;
	top: 0;
	right: 0;
	color: #fff;
	font-size: 26px;
	font-weight: 700;
	display: block
}

#mobile_nav_wrapper .header .logo {
	display: block;
	width: 208px;
	margin: 39px 0 0
}

.mobile-nav,
.mobile-nav ul {
	padding: 117px 20px 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 150;
	-webkit-transition: -webkit-transform .3s;
	-moz-transition: -moz-transform .3s;
	transition: transform .3s;
	-webkit-overflow-scrolling: touch
}

.mobile-nav ul.is-hidden {
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%)
}

.mobile-nav a {
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
	-o-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transition: opacity .3s, -webkit-transform .3s;
	-moz-transition: opacity .3s, -moz-transform .3s;
	transition: opacity .3s, transform .3s;
	font-family: Arial, sans-serif
}

.mobile-nav>li a {
	display: block;
	padding: 16px 0;
	color: #fff;
	position: relative;
	cursor: pointer;
	border-bottom: 0 solid #bfb4a9;
	font-family: wppregular, Arial, sans-serif;
	font-size: 14px;
	font-weight: 400;
	text-transform: uppercase
}

.mobile-nav.move-out>li>a,
.mobile-nav ul.move-out>li>a {
	opacity: 0;
	-webkit-transform: translateX(-100%);
	-moz-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	-o-transform: translateX(-100%);
	transform: translateX(-100%)
}

.mobile-nav li.has-children>a {
	position: relative;
	padding-right: 32px
}

.mobile-nav li.has-children>a:after {
	font-family: icomoon !important;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\e908";
	display: block;
	width: 35px;
	height: 35px;
	line-height: 35px;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	font-size: 24px;
	-webkit-transition: all .2s ease-in-out 0s;
	transition: all .2s ease-in-out 0s
}

.mobile-nav li.go-back>a {
	position: relative;
	padding-left: 28px
}

.mobile-nav li.go-back>a:before {
	content: "";
	display: block;
	width: 14px;
	height: 14px;
	position: absolute;
	left: -10px;
	top: 50%;
	margin-top: -6px;
	transition: all .2s ease-in-out 0s;
	font-family: icomoon !important;
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: "\e909";
	display: block;
	color: #fff;
	line-height: 14px;
	font-size: 28px;
	-webkit-transition: all .2s ease-in-out 0s
}

.mobile-nav>li a.active {
	color: #fff
}

.mobile-nav>li .secondary-nav a.active {
	color: #17154c;
	font-family: wppbold, Arial, sans-serif
}

.top-bar .icn {
	display: inline-block;
	position: relative;
	height: 60px;
	width: 60px;
	text-align: center;
	line-height: 60px;
	font-weight: 700;
	color: #fff;
	vertical-align: middle;
	z-index: 2000;
	text-decoration: none;
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

span.icon-main-menu {
	font-size: 20px;
	color: #fff
}

.top-bar .menu-icon {
	width: 47px;
	height: 47px;
	float: right;
	cursor: pointer;
	display: none
}

span.icon-main-menu {
	display: none
}

.leadership-tabs-wrapper {
	font-size: 0;
	padding: 40px 0 70px
}

.leadership-tabs {
	font-size: 0;
	padding-top: 40px
}

.leadership-tab.active {
	background-color: #b82464;
	color: #fff;
	border: 4px solid #b82464;
	pointer-events: none
}

.leadership-tab {
	display: inline-block;
	vertical-align: middle;
	width: auto;
	height: 72px;
	line-height: 68px;
	background-color: transparent;
	border: 4px solid #b82464;
	font-family: wppbold, Arial, sans-serif;
	font-size: 20px;
	color: #000;
	margin: 0 11px;
	-webkit-border-radius: 0;
	border-radius: 0;
	padding: 0 20px;
	text-transform: uppercase
}

.leadership-tab:first-child {
	margin: 0 11px 0 0
}

.leadership-tab:hover {
	background-color: #e0e0e0
}

.leadership-list {
	font-size: 0;
	margin: 60px 0 0 -32px
}

.leadership-tab-container.active {
	display: block
}

.leadership-tab-container {
	display: none
}

.wrapper.leader {
	padding: 0 30px 40px
}

.gallery {
	clear: both;
	display: inline-block;
	width: 100%;
	background-color: transparent;
	padding-bottom: 0;
	padding-top: 0;
	margin-top: -5px;
	margin-bottom: 0
}

.thumbnail {
	width: 31%;
	height: 380px;
	text-align: center;
	float: left;
	margin-top: 0;
	margin-bottom: 40px;
	margin-left: 1%;
	margin-right: 1%;
	border-radius: 3px
}

.cards {
	width: 100%;
	height: auto;
	max-width: 380px;
	max-height: 380px
}

.gallery .thumbnail .hfour {
	top: -109px;
	left: 9px
}

.gallery .thumbnail h4 {
	margin-top: 5px;
	margin-bottom: 0;
	background-color: #b82464;
	font-family: wppbold, Arial, sans-serif;
	font-size: 18px;
	width: 60%;
	padding-top: 12px;
	position: relative;
	top: -90px;
	left: 10px;
	height: 30px;
	text-transform: uppercase;
	color: #fff;
	text-align: left;
	padding-left: 20px;
	padding-right: 20px
}

.gallery .thumbnail .pee {
	top: -109px;
	left: 9px
}

.gallery .thumbnail .tag {
	color: #fff;
	padding-bottom: 10px;
	padding-top: 10px;
	position: relative;
	width: 70%;
	top: -90px;
	left: 10px;
	background-color: #17154c;
	text-align: left;
	padding-left: 20px;
	padding-right: 20px;
	font-family: wppbold, Arial, sans-serif;
	font-size: 16px
}

.leadership-list {
	font-size: 0;
	margin: 60px 0 0 -32px;
	text-align: center
}

.leadership-list .cd-item,
.directors .cd-item {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 25%;
	padding: 0 0 32px 32px
}

.directors .cd-item {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 25%;
	padding: 0
}

.directors .cd-item {
	width: 100%;
	list-style-type: none;
	display: block
}

.l-image {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 450px;
	background-color: #e9e9e9;
	overflow: hidden;
	display: block;
	position: relative;
	-webkit-transform: perspective(1000px) translate3d(0, 0, 0);
	transform: perspective(1000px) translate3d(0, 0, 0);
	-webkit-transition: all 1.5s ease 0s;
	transition: all 1.5s ease 0s
}

.l-image:hover {
	opacity: .6;
	-webkit-transform: perspective(1000px) translate3d(0, 0, 150px);
	transform: perspective(1000px) translate3d(0, 0, 150px)
}

.leadership-list .cd-trigger,
.directors .cd-trigger {
	position: relative;
	display: block;
	overflow: hidden
}

.leadership-list .cd-trigger img,
.directors .cd-trigger img {
	display: block;
	opacity: 1;
	-webkit-transition: opacity .35s, -webkit-transform .6s;
	transition: opacity .35s, transform .6s;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1)
}

.leadership-list .caption,
.directors .caption {
	display: block;
	text-align: left;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 0;
	height: auto;
	padding: 0
}

.leadership-list .caption span,
.directors .caption span {
	display: block;
	color: #fff
}

.leadership-list .caption .title,
.directors .caption .title {
	font-family: wppbold, Arial, sans-serif;
	font-size: 18px;
	background: 0 0;
	padding: 9px 16px;
	text-transform: uppercase;
	background-color: #b82464;
	width: 70%
}

.leadership-list .caption .title:hover,
.directors .caption .title:hover {
	color: #000
}

.leadership-list .caption .designation,
.directors .caption .designation {
	font-size: 16px;
	font-family: wppbold, Arial, sans-serif;
	background-color: #17154c;
	padding: 15px 16px;
	width: 90%;
	text-transform: capitalize
}

.leadership-list .caption .designation:first-child {
	font-size: 12px
}

.directors {
	font-size: 0;
	margin: 0;
	text-align: center
}

.directors span.icon-short-arrow-right {
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 100%;
	text-align: center;
	position: absolute;
	right: -22px;
	top: 45%;
	margin-top: -30px;
	font-size: 50px;
	font-weight: bolder;
	background-color: #17154c;
	color: #fff
}

.directors span.icon-short-arrow-left {
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 100%;
	text-align: center;
	line-height: 50px;
	position: absolute;
	left: -22px;
	top: 45%;
	margin-top: -30px;
	font-size: 50px;
	font-weight: bolder;
	background-color: #17154c;
	color: #fff
}

.post {
	font-family: wppblack, Arial, sans-serif;
	font-size: 16px;
	font-weight: 900;
	line-height: 2.03;
	letter-spacing: normal;
	color: #b82464;
	display: block
}

.d-cap {
	padding: 17px 0;
	display: block
}

.d-cap p {
	line-height: 1.35;
	margin-bottom: 0;
	font-size: 20px
}

.d-intro {
	font-family: wppregular, Arial, sans-serif;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.6;
	color: #4a4a4a
}

.leader .table {
	padding: 0 0 50px
}

img.profile {
	padding-left: 0
}

h1.mgmnt {
	padding: 25px 0 30px
}

h1.mgmnt:before {
	margin-left: 0
}

.conts .table {
	padding: 90px 0
}

.conts .lefty {
	width: 40%;
	padding: 70px 31px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 400px;
	position: relative
}

.conts .lefty:before {
	opacity: .6;
	background-color: #07043d;
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;
	display: none
}

.conts .righty {
	padding-left: 24px
}

.reach {
	font-family: wppblack, Arial, sans-serif;
	font-size: 118.8px;
	text-transform: uppercase;
	font-weight: 900;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #fff;
	z-index: 2;
	position: relative;
	display: none
}

p.cont {
	letter-spacing: normal
}

p.hr {
	font-family: wppmedium, Arial, sans-serif;
	font-size: 28px;
	font-weight: 500;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
	color: #272460;
	padding: 36px 0
}

a.hr-head {
	text-decoration: underline;
	color: #b82464;
	font-weight: 700
}

.group {
	font-size: 24px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: #4a4a4a;
	display: block;
	margin-bottom: 70px;
	font-family: wppregular, Arial, sans-serif
}

.wrapper.conts2 {
	padding-bottom: 40px
}

.conts2 .table {
	border-top: 2px solid #ed174c;
	padding-top: 30px;
	margin-top: 50px
}

.conts2 .col {
	display: table-cell;
	vertical-align: text-top;
	text-align: left;
	padding: 0;
	width: 33.33%
}

.conts2 .middle {
	padding: 0 40px
}

.conts2 .heading {
	font-family: wppblack, Arial, sans-serif;
	font-size: 20px;
	font-weight: 900;
	font-style: normal;
	line-height: 2.5;
	color: #084e8a;
	display: block;
	text-transform: uppercase
}

.conts2 .designtn {
	font-family: wppbold, Arial, sans-serif;
	font-size: 14px;
	font-weight: 700;
	font-style: normal;
	color: #084e8a;
	display: block;
	padding-top: 15px;
	text-transform: uppercase
}

.conts2 .name {
	font-family: wppbold, Arial, sans-serif;
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	color: #4a4a4a;
	display: block
}

.conts2 p {
	font-family: wppmedium, Arial, sans-serif;
	font-size: 16px;
	font-weight: 500;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #4a4a4a;
	width: 56%;
	margin-bottom: 0
}

p.email {
	font-family: wppregular, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: normal;
	color: #4a4a4a;
	width: 100%
}

.select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 4px solid #ed174c !important;
	border-radius: 0;
	height: 44px;
	line-height: 42px
}

.select2-container .select2-selection--single,
#layer_nav_wrapper .select2-container .select2-selection--single {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	height: 60px;
	line-height: 42px;
	user-select: none;
	-webkit-user-select: none
}

.select2-container .select2-selection--single {
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	height: 60px !important;
	user-select: none;
	-webkit-user-select: none
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 53px !important;
	position: absolute;
	top: 1px;
	right: 8px !important;
	width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 53px !important
}

.select2-container--default .select2-selection--single .select2-selection__arrow,
#layer_nav_wrapper .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 26px;
	position: absolute;
	top: 8px;
	right: 1px;
	width: 20px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #444;
	line-height: 53px
}

.select2-container--default .select2-selection--single .select2-selection__arrow,
#layer_nav_wrapper .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 56px;
	position: absolute;
	top: 0;
	right: 1px;
	width: 20px
}

.field {
	max-width: 336px;
	width: 100%;
	display: block;
	font-family: wppbold, Arial, sans-serif;
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	color: #4a4a4a;
	text-transform: uppercase
}

.field-wpp {
	display: none
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #ed174c;
	color: #fff;
	font-family: wppbold, Arial, sans-serif;
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	color: #fff
}

.select2-container--default .select2-results>.select2-results__options {
	max-height: 200px;
	overflow-y: auto;
	font-family: wppbold, Arial, sans-serif;
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	color: #4a4a4a
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #000 transparent;
	border-width: 0 7px 7px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #000 transparent transparent transparent;
	border-style: solid;
	border-width: 8px 7px 0;
	height: 0;
	left: 50%;
	margin-left: -14px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0
}

span.icon-main-menu {
	display: none
}

.investor-rln p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 24px;
	font-weight: 400;
	font-style: normal;
	line-height: 1.33;
	color: #4a4a4a;
	margin-bottom: 75px
}

.investor-rln h1,
.pop-up h1 {
	font-family: wppbold, Arial, sans-serif;
	font-size: 36px;
	font-weight: 700;
	font-style: normal;
	line-height: normal;
	color: #084e8a;
	padding: 68px 0;
	text-transform: uppercase
}

.pop-up h1 {
	padding: 0 0 13px;
	text-transform: capitalize;
	text-align: left
}

.table {
	display: table;
	width: 100%;
	position: relative
}

.annual-rpt {
	display: table-cell;
	vertical-align: middle;
	text-align: left;
	padding: 0;
	width: 60%
}

.agm {
	display: table-cell;
	vertical-align: top;
	text-align: right;
	padding: 0 0 0 12px;
	background-color: #23225b
}

.annual-general-meeting {
	display: block;
	padding: 60px 20px
}

.annual-rpt ul li:nth-child(1) {
	padding-left: 0
}

.annual-rpt ul li {
	vertical-align: top;
	display: inline-block;
	width: calc(25% - 7px);
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 7px 0 0;
	margin: 0
}

.annual-rpt ul li a {
	max-width: 144px
}

.annual-rpt .info {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 12px;
	padding: 28px 0 12px;
	line-height: 1.33;
	color: #084e8a;
	padding: 13px 0 0 20px;
	display: inline-block;
	font-weight: 700;
	position: relative
}

.annual-rpt .info:hover {
	color: #ed174c
}

.annual-rpt .info:before {
	position: absolute;
	left: 0;
	content: "\e90d";
	font-family: icomoon !important;
	font-size: 12px;
	font-weight: 400;
	display: inline-block;
	color: #084e8a
}

a.info:hover:before {
	color: red
}

.annual-rpt .size {
	font-family: wppregular, Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #4a4a4a
}

.annual-rpt .image {
	height: 266px;
	max-width: 188px
}

.annual {
	font-family: wppblack, Arial, sans-serif;
	font-size: 22px;
	font-weight: 900;
	font-style: normal;
	line-height: normal;
	color: #44f6df;
	text-transform: uppercase;
	text-align: left;
	display: block
}

ul.wraps {
	padding: 30px 0 0
}

.wraps li {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: block;
	padding: 0 0 20px;
	vertical-align: text-top;
	width: 100%;
	margin: 0
}

.wraps .l-side {
	display: table-cell;
	vertical-align: top;
	text-align: left;
	padding: 0 10px 0 0;
	width: 100px;
	font-family: wppregular, Arial, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: normal;
	color: #fff
}

.wraps .r-side {
	display: table-cell;
	vertical-align: top;
	text-align: left;
	padding: 0;
	width: auto;
	font-family: wppbold, Arial, sans-serif;
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	line-height: normal;
	color: #fff
}

.links {
	font-family: wppregular, Arial, sans-serif;
	font-size: 16px;
	font-weight: 400;
	color: #fff;
	display: block;
	text-align: left;
	padding-bottom: 18px
}

.pdfs ul li {
	vertical-align: top;
	display: inline-block;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
	margin: 0
}

.pdfs ul li a {
	font-family: wpp bold, Arial, sans-serif;
	font-size: 12px;
	padding: 10px 0 10px 24px;
	line-height: 1.33;
	color: #fff;
	display: inline-block;
	font-weight: 700;
	position: relative
}

.pdfs ul li a:hover {
	color: #c60e3b
}

.pdfs ul li a:before {
	position: absolute;
	left: 0;
	content: "\e90d";
	font-family: icomoon !important;
	font-size: 11px;
	font-weight: 400;
	display: inline-block;
	color: #fff
}

.p-size {
	font-family: wpplight, Arial, sans-serif;
	font-size: 8px;
	font-weight: 300;
	font-style: normal;
	color: #fff;
	display: block;
	padding: 4px 0 0
}

.wrapper.investor-rln .accordion .title {
	color: #fff;
	font-family: wppmedium, Arial, sans-serif;
	font-size: 28px;
	font-weight: 500;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	color: #084e8a;
	padding: 30px 0;
	display: block
}

.wrapper.investor-rln .new-accord .title {
	color: #fff;
	font-family: wppmedium, Arial, sans-serif;
	font-size: 28px;
	font-weight: 500;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: normal;
	color: #084e8a;
	padding: 30px 0;
	display: block
}

.wrapper.investor-rln .accordion .title:after {
	display: block;
	position: absolute;
	right: 22px;
	top: 0;
	bottom: 0;
	margin: auto;
	content: "\e90e";
	font-family: icomoon !important;
	font-size: 40px;
	display: block;
	color: #31373d;
	background-color: transparent;
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transition: all .3s ease 0s;
	transition: all .3s ease 0s
}

.wrapper.investor-rln .accordion .desc {
	padding: 50px 0;
	display: none
}

.searchy form {
	overflow: hidden;
	position: relative;
	background: #ddd;
	border-top: 1px solid #dbdbdb
}

.search-box-wrapper {
	display: block;
	height: 52px;
	border: 1px solid #c1c1c1;
	position: relative
}

.search-box-wrapper input {
	display: block;
	width: 100%;
	height: 50px;
	border: 0;
	margin: 0;
	background-color: transparent;
	padding: 0 102px 0 16px;
	color: #616262;
	font-size: 16px;
	font-family: wppregular, Arial, sans-serif;
	line-height: 50px
}

.search-box-wrapper button {
	display: block;
	max-width: 90px;
	height: 50px;
	position: absolute;
	right: 0;
	top: 0;
	margin: 0;
	border: 0;
	background-color: #084e8a;
	color: #fff;
	cursor: pointer;
	font-size: 36px;
	padding: 0 20px
}

.search-results {
	padding: 20px 0 0
}

.search-results li:first-child {
	border-top: 1px solid #d9dfe9
}

.search-results li {
	border-bottom: 1px solid #d9dfe9;
	padding: 30px 0
}

.search-results .header {
	display: table;
	width: 100%
}

.search-results .header .col {
	display: table-cell;
	vertical-align: top
}

.search-results .category {
	font-family: wppbold, Arial, sans-serif;
	display: block;
	text-transform: uppercase;
	color: #f90333;
	font-size: 17px;
	margin: 0 0 12px
}

.search-results h3 {
	font-family: wppmedium, Arial, sans-serif;
	font-size: 25px;
	margin-bottom: 20px
}

.search-results #main_content p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 15px
}

.search-results .btn.blue {
	font-family: wpp medium, Arial, sans-serif;
	height: 30px;
	line-height: 30px;
	display: block;
	max-width: 136px;
	width: 100%;
	background-color: #ccc;
	color: #000;
	text-align: center;
	font-size: 15px;
	font-weight: 700;
	margin: 30px 0 0
}

.wrapper.searchy .icon-short-arrow-left:before {
	content: "\e909";
	font-size: 30px
}

.wrapper.searchy .icon-short-arrow-right:before {
	content: "\e908";
	font-size: 30px
}

.pop-up {
	display: table;
	width: 100%;
	height: auto;
	background-color: #fff;
	position: relative
}

.popcol-left {
	text-align: left;
	display: table-cell;
	vertical-align: text-top;
	width: 30%;
	padding-right: 52px
}

.popcol-right {
	text-align: right;
	display: table-cell;
	vertical-align: middle
}

.pop-up .overall {
	width: 100%;
	display: block;
	vertical-align: text-top;
	text-align: left
}

.modal-item.bid {
	max-width: 800px;
	width: 800px;
	background-color: #fff;
	border-radius: 20px
}

.pop-logo {
	display: block;
	max-width: 260px;
	vertical-align: middle;
	padding: 0;
	margin: 0 0 30px
}

.pop-up p {
	font-family: wppregular, Arial, sans-serif;
	font-size: 16px;
	line-height: 26px
}

.pop-up span {
	font-family: wppbold, Arial, sans-serif;
	font-size: 16px;
	line-height: 32px
}

.pop-up a.icn {
	font-size: 15px;
	text-align: center;
	color: #084e8a
}

.pop-up .icn:before {
	font-size: 13px;
	color: #084e8a;
	top: 1px
}

.carousel-item {
	opacity: 1
}

.carousel-item.active.right,
.carousel-item.next {
	left: 0;
	-webkit-transition: opacity .7s ease-in-out;
	-moz-transition: opacity .7s ease-in-out;
	-ms-transition: opacity .7s ease-in-out;
	-o-transition: opacity .7s ease-in-out;
	transition: opacity .7s ease-in-out;
	opacity: 0;
	transform: translate3d(0, 0, 0)
}

.carousel-item.active.left,
.carousel-item.prev {
	left: 0;
	-webkit-transition: opacity .7s ease-in-out;
	-moz-transition: opacity .7s ease-in-out;
	-ms-transition: opacity .7s ease-in-out;
	-o-transition: opacity .7s ease-in-out;
	transition: opacity .7s ease-in-out;
	opacity: 0;
	transform: translate3d(0, 0, 0)
}

@media only screen and (max-width:1500px) {
	.has-children {
		padding: 0 4%
	}

	header .top-bar .col-middle {
		padding-left: 0
	}
}

@media only screen and (max-width:1500px) {
	.caption-menu {
		top: 22%;
		width: 50%;
		z-index: 2
	}

	.caption-menu p {
		padding: 30px 0 37px
	}

	.homepage .table {
		height: 650px
	}

	.customNavigation {
		bottom: 26px
	}
}

@media only screen and (max-width:1400px) {
	.has-children {
		padding: 0 2%
	}

	.caption-menu {
		width: 38%
	}
}

@media only screen and (max-width:1366px) {
	.has-children {
		padding: 0 1.3%
	}

	.caption-menu {
		top: 25%;
		width: 48%;
		top: 24%
	}

	.proin {
		font-size: 34px
	}

	.homepage .table {
		height: 676px
	}

	.homepage .table {
		height: 702px
	}

	header .top-bar .col-middle {
		padding-left: 20px
	}

	.caption-menu p {
		padding: 40px 0 20px
	}

	.caption-menu>ul>li>a {
		line-height: 3
	}

	#main_nav>li {
		margin-left: 0
	}
}

@media only screen and (max-width:1280px) {
	#main_nav>li>a {
		font-size: 14px
	}

	header .top-bar {
		padding: 0 35px
	}

	.proin {
		font-size: 30px
	}

	.caption-menu {
		width: 60%;
		top: 23%
	}
}

@media only screen and (max-width:1100px) {
	header .top-bar .col-middle {
		display: none
	}

	header .top-bar .col.right,
	header .bottom-bar .col.right {
		width: 50%
	}

	header .btn-search {
		font-weight: 400
	}

	span.icon-main-menu {
		font-size: 29px;
		color: #fff;
		width: 60px;
		height: 60px;
		line-height: 90px;
		display: inline-block;
		position: relative
	}

	header .top-bar {
		line-height: 90px
	}

	header .btn-search {
		font-size: 34px
	}

	.caption-menu {
		position: absolute;
		top: 30%;
		left: 131px;
		width: 60%;
		z-index: 2
	}

	.has-children {
		padding: 0
	}

	.top-bar .menu-icon {
		display: block
	}

	.gradient {
		width: 100%;
		height: 100vh;
		object-fit: cover;
		position: relative;
		background-color: #000;
		opacity: .7
	}

	.stats .statistics h1 {
		font-size: 18px
	}

	.statistics .lefty,
	.statistics .righty {
		font-size: 30px
	}

	.homepage .tcell-left {
		padding: 30px 24px 164px 49px
	}

	.statistics .lefty span,
	.statistics .righty span {
		font-size: 25px
	}

	.homepage h1,
	.searchy h1 {
		font-size: 20px;
		padding-bottom: 20px
	}

	.homepage .table {
		height: 650px
	}

	.customNavigation {
		right: 21px;
		bottom: 20px
	}

	.video .icon-button {
		font-size: 89px
	}

	.owl-carousel.off {
		display: block
	}

	.featured-work {
		bottom: -39px
	}

	.banners-cption h1,
	.leader h1 {
		font-size: 30px
	}

	.banners-cption {
		bottom: 66px;
		left: 68px
	}

	.inside-banners {
		min-height: 320px;
		margin: 0 0 40px
	}

	.text h4 {
		font-size: 35px;
		padding: 30px 0
	}

	.wrapper {
		padding: 0 70px
	}

	.inside-banners.blg {
		min-height: 250px
	}

	.text p {
		font-size: 24px
	}

	.blog .tbl h4 {
		font-size: 80.6px
	}

	.blog .tbl span {
		font-size: 60.6px;
		font-weight: 200
	}

	.blog .tbl p {
		font-size: 20px
	}

	.inside-banners.sts {
		min-height: 243px
	}

	.padding {
		padding: 20px 0 30px
	}

	.pics .text h4 {
		font-size: 20px
	}

	.recognition .ttle {
		font-size: 17.2px;
		max-width: 80%
	}

	.tbl.pics {
		height: 350px;
		padding-bottom: 30px
	}

	.conts .table {
		padding: 40px 0
	}

	.reach {
		font-size: 80.8px
	}

	p.hr {
		font-size: 20px;
		padding: 30px 0
	}

	.r-side {
		font-size: 28px
	}

	.wrap li {
		padding: 25px 0
	}

	.l-side {
		padding: 0 30px 0 0;
		vertical-align: middle;
		width: 200px
	}

	.r-side {
		font-size: 20px;
		vertical-align: middle
	}

	.leadership-tabs-wrapper {
		padding: 20px 0 30px
	}

	.leadership-list .caption .title,
	.directors .caption .title {
		width: 100%
	}

	.leadership-list .caption .designation,
	.directors .caption .designation {
		width: 100%
	}

	.about-us h2,
	.company h2,
	h2.projects {
		padding: 30px 0 20px
	}

	.about-us p,
	.company p,
	.about-us,
	p {
		line-height: 1.65
	}

	.wrapper.company .accordion .title {
		padding: 30px 101px 30px 0
	}

	.wrapper.company .new-accord .title {
		padding: 30px 101px 30px 0
	}

	.search-results h3 {
		font-size: 22px;
		margin-bottom: 15px
	}

	.conts2 .middle {
		padding: 0 20px
	}

	.conts2 .heading {
		font-size: 18px
	}

	.conts2 p {
		width: 80%
	}
}

@media only screen and (max-width:990px) {
	.home-slider.owl-carousel {
		display: block
	}

	.caption-menu>ul>li>a {
		color: #fff
	}

	.caption-menu ul>li>a:before {
		color: #fff
	}

	.accordion .column>a {
		padding-right: 0
	}

	p.info {
		font-size: 14px
	}
}

@media only screen and (max-width:768px) {
	.homepage .tcell-left {
		padding: 30px 20px 30px 30px
	}

	.story {
		font-size: 22px;
		padding: 14px 0
	}

	.customNavigation {
		right: 21px;
		bottom: 10px
	}

	.chela-01 {
		font-size: 25px
	}

	.statistics .tbl {
		padding: 20px 30px 0 0
	}

	.statistics .lefty,
	.statistics .righty {
		font-size: 20px
	}

	.statistics .lefty,
	.statistics .righty {
		padding-right: 24px
	}

	.stats .statistics {
		padding: 30px 25px 59px
	}

	.homepage .table {
		height: 800px
	}

	.footer .lefty {
		padding-right: 0;
		width: 100%;
		display: block
	}

	.footer .righty {
		display: block;
		width: 100%;
		padding-top: 40px;
		padding-bottom: 40px
	}

	.foot {
		padding: 0 40px
	}

	.footer .lefty .social-media-icons {
		display: block;
		text-align: left
	}

	.intro p {
		text-align: left;
		display: block
	}

	.proin {
		font-size: 38px
	}

	.homepage .tcell-right {
		min-height: 727px;
		display: none
	}

	.homepage .tcell-left {
		width: 100%
	}

	.homepage .table {
		height: auto
	}

	.stats .col-1 {
		display: block;
		width: 100%;
		padding: 0;
		position: relative
	}

	.stats .col-2 {
		display: block;
		position: relative
	}

	.video span {
		display: flex;
		position: relative;
		height: 100%;
		width: 100%
	}

	.stats .statistics {
		padding: 30px;
		width: 100%;
		display: block
	}

	.featured-work {
		position: relative;
		left: 0;
		font-size: 0;
		bottom: 0
	}

	.stats .video {
		height: 250px;
		width: 100%;
		display: block
	}

	.video .icon-button {
		font-size: 69px
	}

	.stats .story-chela {
		padding: 30px
	}

	.foot {
		min-height: auto
	}

	.statistics .tbl {
		padding: 20px 0 0 0
	}

	.text h4 {
		font-size: 27px;
		padding: 30px 0
	}

	.banners-cption h1,
	.leader h1 {
		font-size: 25px
	}

	.tbl.pics.revolution .lefty {
		padding-right: 20px
	}

	.recognition .small-text {
		width: 80%
	}

	.tbl.pics {
		padding-bottom: 15px
	}

	a.recognition:before {
		background-color: rgba(0, 0, 0, .8)
	}

	.careers .righty {
		width: 50%
	}

	.careers .image {
		height: 380px
	}

	.drctn h4,
	.policy a.h4 {
		font-size: 28px
	}

	.careers {
		padding-top: 40px
	}

	.drctn p {
		font-size: 20px;
		width: 90%
	}

	.policy.drctn p {
		width: 90% !important
	}

	.careers p {
		ine-height: 1.7
	}

	p.cont {
		font-size: 20px;
		line-height: 34px
	}

	.reach {
		font-size: 36.8px;
		line-height: 45px
	}

	p.hr {
		font-size: 14px;
		padding: 20px 0
	}

	.group {
		font-size: 20px;
		margin-bottom: 30px
	}

	.field {
		font-size: 16px
	}

	.conts2 .heading {
		font-size: 18px;
		line-height: 1.7
	}

	.conts2 .designtn {
		line-height: 3.2
	}

	.conts2 .col {
		display: inline-block;
		width: 50%
	}

	.conts2 .name {
		line-height: 1.2
	}

	.conts2 p {
		width: 90%
	}

	.investor-rln h1,
	.pop-up h1 {
		font-size: 30px;
		padding: 30px 0
	}

	.pop-up h1 {
		padding: 0 0 30px
	}

	.investor-rln p {
		font-size: 20px;
		margin-bottom: 30px
	}

	.agm {
		display: block;
		width: 100%
	}

	.annual-rpt {
		width: 100%;
		display: block;
		padding: 0 0 30px
	}

	.l-image {
		background-position: center 2%
	}

	.leadership-tab {
		height: 45px;
		line-height: 43px;
		border: 2px solid #b82464;
		font-size: 13px;
		margin: 0 11px;
		padding: 0 13px
	}

	.leadership-list {
		font-size: 0;
		margin: 30px 0 0 -32px;
		text-align: center
	}

	.leadership-list .cd-item {
		display: inline-block;
		vertical-align: top;
		position: relative;
		width: 50%;
		padding: 0 0 32px 32px
	}

	.accordion .column {
		display: block;
		width: 100%;
		padding: 0
	}

	.c-logo {
		margin: 0 auto
	}

	.wrapper.company .accordion .title {
		padding: 30px 66px 30px 0
	}

	.wrapper.company .new-accord .title {
		padding: 30px 66px 30px 0
	}

	.wrapper.company .accordion .title {
		font-size: 20px
	}

	.wrapper.company .new-accord .title {
		font-size: 20px
	}

	.c-ttle {
		text-align: center
	}

	.accordion span.icn {
		text-align: center
	}

	.accordion .column {
		padding: 0 0 15px
	}

	header .top-bar {
		background-color: #000
	}

	.search-results h3 {
		font-size: 19px;
		margin-bottom: 10px
	}

	.stats .col-2 {
		display: none
	}

	.modal-item.bid {
		max-width: 100%;
		width: 100%;
		border-radius: 10px
	}

	.hvrbox-layer_top.hvrbox-layer_slideup {
		-moz-transform: translateY(150%);
		-webkit-transform: translateY(150%);
		-ms-transform: translateY(150%);
		transform: translateY(150%);
		opacity: 1;
		height: 40%
	}

	.hvrbox .hvrbox-text p {
		font-size: 18px
	}
}

@media only screen and (max-width:650px) {

	.conts .righty,
	.conts .lefty {
		display: block;
		width: 100%;
		padding: 30px 0 0
	}

	.conts .lefty {
		padding: 20px 31px;
		text-align: center
	}

	.conts .table {
		padding: 20px 0
	}

	.group {
		font-size: 16px;
		margin-bottom: 20px
	}

	.conts2 .col {
		display: block;
		width: 100%;
		border-bottom: 1px dotted #000
	}

	.conts2 .middle {
		padding: 0
	}

	.conts2 .heading {
		font-size: 16px;
		line-height: 1.7;
		padding: 20px 0 0
	}

	.conts2 .name {
		font-size: 16px
	}

	.select2-container--default .select2-selection--single {
		border: 2px solid #ed174c !important
	}

	.conts2 .table {
		padding-top: 30px;
		margin-top: 30px
	}

	.l-side {
		width: 100%;
		display: block;
		padding: 0;
		line-height: 2;
		font-size: 16px
	}

	.r-side {
		font-size: 19px;
		width: 100%;
		display: block;
		line-height: 1.2
	}

	.investor-rln p {
		font-size: 16px;
		margin-bottom: 20px
	}

	.investor-rln h1,
	.pop-up h1 {
		font-size: 20px;
		padding: 16px 0
	}

	.pop-up h1 {
		padding: 0 0 16px
	}

	.wrapper.investor-rln .accordion .title {
		font-size: 20px
	}

	.wrapper.investor-rln .accordion .title:after {
		font-size: 28px
	}

	.wrapper.investor-rln .accordion .title {
		font-size: 20px;
		padding: 25px 0;
		display: block
	}

	.leader .lefty {
		display: block;
		width: 100%
	}

	.leader .righty {
		width: 100%;
		padding-left: 0;
		display: block
	}

	img.profile {
		padding-left: 0;
		margin-bottom: 15px
	}

	.d-cap {
		font-size: 16px
	}

	.d-intro,
	.d-cap p,
	.leader .righty {
		font-size: 16px;
		line-height: 1.35
	}

	.leader .table {
		padding: 0 0 20px
	}

	.leadership-tab {
		height: 38px;
		line-height: 36px;
		border: 1px solid #b82464;
		font-size: 12px;
		padding: 0 16px
	}

	.leadership-tab {
		font-size: 12px
	}

	.leadership-tab {
		margin: 0 -7px;
		text-transform: capitalize
	}

	.field {
		max-width: 336px;
		width: 100%;
		display: block;
		font-family: wppbold, Arial, sans-serif;
		font-size: 20px;
		font-weight: 700;
		font-style: normal;
		color: #4a4a4a;
		text-transform: uppercase
	}

	.leadership-tabs-wrapper {
		display: none
	}

	.field-wpp {
		max-width: 100%;
		width: 100%;
		display: block;
		font-family: wppbold, Arial, sans-serif;
		font-size: 14px;
		font-weight: 700;
		font-style: normal;
		color: #4a4a4a;
		text-transform: uppercase
	}

	.field-wpp .select2-container .select2-selection--single {
		height: 50px
	}

	.field-wpp .select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 47px
	}

	.field-wpp .select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 46px
	}

	.field-wpp .select2-container--default .select2-selection--single .select2-selection__arrow b {
		margin-left: -11px
	}

	.about-us p,
	.company p,
	.about-us,
	.about-us ul li {
		font-size: 16px
	}

	p {
		font-size: 16px
	}

	.about-us h2,
	.company h2,
	h2.projects {
		font-size: 22px;
		padding: 20px 0
	}

	.search-results h3 {
		font-size: 18px;
		margin-bottom: 10px
	}
}

@media only screen and (max-width:570px) {
	header .top-bar {
		padding: 0 30px
	}

	header .btn-search {
		font-size: 22px
	}

	span.icon-main-menu {
		font-size: 22px
	}

	header .btn-search {
		height: 10px;
		width: 10px;
		line-height: 8px
	}

	.proin {
		font-size: 24px;
		text-align: center
	}

	.caption-menu {
		left: 0;
		width: 80%;
		top: 26%;
		margin: 0 37px
	}

	.caption-menu p {
		padding: 30px 0;
		text-align: center
	}

	.caption-menu ul {
		padding: 0 0 0 30px
	}

	.homepage p {
		text-align: center
	}

	.homepage h1,
	.searchy h1 {
		font-size: 19px;
		text-align: center
	}

	.homepage a.arrowright {
		display: block;
		text-align: center
	}

	.homepage .statistics p {
		font-size: 15px
	}

	.stats .statistics h1 {
		font-size: 16px
	}

	.statistics .tbl {
		padding: 0
	}

	.statistics .lefty,
	.statistics .righty {
		font-size: 22px;
		padding-right: 0;
		display: block;
		text-align: center;
		width: 100%;
		padding: 10px 0
	}

	.video .icon-button {
		font-size: 57px
	}

	.stats .video {
		height: 200px
	}

	.intro p {
		font-size: 16px;
		text-align: center
	}

	.footer .intro {
		font-size: 18px;
		padding: 20px 0
	}

	.footer .righty {
		padding-top: 0;
		padding-bottom: 30px;
		text-align: left
	}

	.footer .block,
	.coloured-strip .block {
		display: inline-block;
		width: 50%;
		padding: 0
	}

	.footer .block ul li a {
		font-size: 14px
	}

	.tbl.footer {
		margin-bottom: 0
	}

	.logo-strip.tbl .lefty {
		padding-right: 0;
		width: auto
	}

	.logo-strip.tbl {
		padding-top: 30px
	}

	.ft-logo {
		max-width: 210px;
		margin: 0 auto
	}

	.coloured-strip.tbl {
		display: none
	}

	.logo-strip.tbl .righty {
		display: none
	}

	.footer .block h3 {
		margin: 30px 0 10px
	}

	.footer .lefty .social-media-icons {
		padding-bottom: 30px;
		border-bottom: 1px dotted #fff
	}

	.caption-menu>ul>li>a {
		font-size: 17px
	}

	.caption-menu ul>li>a:before {
		font-size: 14px
	}

	.footer .lefty .social-media-icons .icon {
		font-size: 20px
	}

	.main-search input[type=search] {
		font-size: 18px
	}

	.main-search .tbl {
		padding: 0 30px
	}

	.main-search .btn-search-close {
		right: 18px
	}

	.footer .lefty .social-media-icons {
		display: block;
		text-align: center
	}

	.banners-cption h1,
	.leader h1 {
		font-size: 16px
	}

	.banners-cption {
		bottom: 33px;
		left: 0;
		margin: 0 30px;
		width: 84%
	}

	.inside-banners {
		min-height: 230px
	}

	.text h4 {
		font-size: 20px;
		padding: 10px 0 30px
	}

	.wrapper {
		padding: 0 30px
	}

	p {
		font-size: 16px
	}

	.text p {
		font-size: 16px;
		margin-bottom: 25px
	}

	.inside-banners {
		margin: 0 0 20px
	}

	.blog .tbl p {
		font-size: 12px;
		line-height: 18px
	}

	.blog .tbl h4 {
		font-size: 22.6px
	}

	.blog .tbl span {
		font-size: 18.6px;
		font-weight: 200
	}

	.stats .col.right {
		padding-left: 0;
		padding-right: 0
	}

	.stats .col.left {
		padding-right: 0;
		padding-left: 0
	}

	.inside-banners.sts {
		min-height: 150px
	}

	.padding {
		padding: 20px 0
	}

	.recognition {
		height: 200px
	}

	.tbl.pics .lefty,
	.tbl.pics .righty {
		width: 100%;
		display: block
	}

	.tbl.pics {
		height: auto
	}

	.cont-wrap {
		z-index: 20;
		margin: 0 20px;
		left: 0;
		width: 90%
	}

	.recognition .small-text {
		text-align: center;
		width: 100%
	}

	.recognition .ttle {
		font-size: 15.2px;
		max-width: 100%;
		text-align: center
	}

	a.recognition:before {
		background-color: rgba(0, 0, 0, .9)
	}

	.row-col {
		border-bottom: 0 solid #fff
	}

	.tbl.pics .righty .recognition .ttle {
		font-size: 15.2px
	}

	.tbl.pics.revolution .lefty {
		padding-right: 0
	}

	.banners-cption h1,
	.leader h1 {
		padding: 17px 0 0
	}

	.careers .righty {
		width: 100%;
		display: block
	}

	.careers .lefty {
		width: 100%;
		display: block
	}

	.careers .drctn,
	.drctn {
		padding: 30px
	}

	.careers {
		padding-top: 0
	}

	.careers p {
		font-size: 15px;
		line-height: 1.5;
		width: 100%
	}

	.careers .image {
		height: 150px
	}

	a.icn {
		font-size: 15px
	}

	.drctn h4,
	.policy a.h4 {
		font-size: 20px
	}

	.drctn p {
		font-size: 16px
	}

	.policy.drctn {
		padding-bottom: 40px
	}

	.policy.drctn p {
		width: 100% !important
	}

	p.cont {
		font-size: 16px;
		line-height: 28px
	}

	ul.pagination li a {
		font-size: 14px;
		width: 30px;
		height: 30px;
		line-height: 30px
	}

	.top-bar .col.right {
		padding-right: 10px
	}

	.annual-rpt .pic-wrapper {
		display: none
	}

	.wrapper.investor-rln .accordion .desc {
		padding: 0 0 30px;
		display: none
	}

	.annual-rpt ul li {
		display: block;
		width: 100%;
		background-color: #ccc;
		padding: 15px;
		margin-bottom: 5px
	}

	.annual-rpt ul li:nth-child(1) {
		padding-left: 15px
	}

	.annual-rpt {
		padding: 0 0 10px
	}

	.annual {
		font-size: 14px
	}

	.annual-general-meeting {
		padding: 30px 20px
	}

	.wraps .r-side {
		font-size: 17px
	}

	.directors .cd-item .pic-wrapper {
		display: block
	}

	.leadership-list .cd-item,
	.directors .cd-item {
		padding: 0 0 32px 0
	}

	.leadership-list .caption .designation,
	.directors .caption .designation {
		width: 100%;
		font-size: 14px
	}

	.leadership-list .caption .title,
	.directors .caption .title {
		width: 100%;
		font-size: 14px
	}

	.leadership-list {
		margin: 25px 0 0
	}

	.leader h1 {
		padding-bottom: 24px
	}

	h1.mgmnt:before {
		margin-left: 0
	}

	.wrapper.leader {
		padding: 0 30px 30px
	}

	.leadership-list .cd-item {
		display: block;
		vertical-align: top;
		position: relative;
		width: 100%;
		padding: 0 0 32px
	}

	.directors .cd-item {
		display: block;
		position: relative;
		width: 100%;
		padding: 0 0 20px
	}

	.l-image {
		height: 350px
	}

	.wrapper.company .accordion .title {
		font-size: 16px;
		padding: 20px 0
	}

	.wrapper.company .new-accord .title {
		font-size: 16px;
		padding: 20px 0
	}

	.popcol-right,
	.popcol-left {
		text-align: left;
		display: block;
		width: 100%;
		padding: 0
	}

	.popcol-left {
		padding-bottom: 15px
	}

	.pop-logo {
		max-width: 100px;
		padding: 0;
		margin: 0 auto 30px
	}

	.fancybox-content {
		padding: 30px !important
	}

	.pop-up h1 {
		text-align: center
	}

	.pop-up p {
		text-align: center
	}

	.about-us ul li {
		line-height: 23px
	}

	.hvrbox .hvrbox-text h3.heading {
		font-size: 16px
	}

	.hvrbox .hvrbox-text {
		width: 90%
	}

	.hvrbox .hvrbox-text p {
		font-size: 14px
	}

	.conts .lefty {
		height: 200px
	}

	.directors span.icon-short-arrow-right {
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 34px
	}

	.directors span.icon-short-arrow-left {
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 34px
	}

	.homepage .stats .col-1 .video .image {
		background-position: center 67%
	}
}

@media only screen and (max-width:375px) {
	.caption-menu {
		margin: 0 37px
	}

	header .top-bar {
		line-height: 67px
	}

	span.icon-main-menu {
		font-size: 16px;
		width: 30px;
		height: 30px;
		line-height: 30px
	}

	.story {
		font-size: 20px;
		padding: 10px 0
	}

	.client {
		font-size: 16px
	}

	.chela-01 {
		font-size: 22px
	}

	.footer .block h3 {
		font-size: 14px
	}

	.banners-cption h1 {
		font-size: 15px
	}

	.leader h1 {
		font-size: 19px
	}

	.text h4 {
		font-size: 18px
	}

	.wrapper {
		padding: 0 27px
	}

	.reach {
		font-size: 20.8px
	}

	.conts .lefty {
		padding: 10px
	}

	.inside-banners {
		min-height: 200px
	}

	.group {
		font-size: 15px
	}

	.field {
		max-width: 100%;
		width: 100%
	}

	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 44px
	}

	.select2-container .select2-selection--single,
	#layer_nav_wrapper .select2-container .select2-selection--single {
		height: 47px;
		line-height: 25px
	}

	.select2-container--default .select2-selection--single .select2-selection__arrow b {
		top: 40%;
		width: 0
	}

	.field {
		font-size: 13px
	}

	.r-side {
		font-size: 16px
	}

	h5 span {
		font-size: 14px;
		line-height: 60px
	}

	.top-bar .col.right {
		padding-right: 0
	}

	.conts .lefty {
		height: 150px
	}

	.caption-menu {
		top: 18%
	}

	.proin {
		font-size: 18px
	}
}

@media only screen and (max-width:320px) {
	.caption-menu {
		top: 18%
	}

	.proin {
		font-size: 18px
	}
}